@font-face {
  font-family: 'Quadraat';
  src: url('https://latin-cards.fsvehla.at/fonts/Quadraat-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Quadraat';
  font-style: italic;

  src: url('https://latin-cards.fsvehla.at/fonts/Quadraat-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'Quadraat';
  font-weight: bold;

  src: url('https://latin-cards.fsvehla.at/fonts/Quadraat-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Quadraat';

  font-weight: bold;
  font-style: italic;

  src: url('https://latin-cards.fsvehla.at/fonts/Quadraat-BoldItalic.otf') format('opentype');
}