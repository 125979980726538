@use "sass:color";

@import "include-styles/font-quadraat";

/** reset **/
html {
  box-sizing: border-box;
}


*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;

  font-weight: normal;
}

ol, ul {
  list-style: none;
}

td, th, ul, li {
  padding: 0;
  margin: 0;
}

/** end: reset */

$font-family-ui: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

$font-family-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono", "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro", "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

$font-family-serif: Quadraat;

$pantone-classic-blue: hsl(213, 56%, 30%);

@mixin mobile-only {
  @media screen and (max-width: 599px) {
    @content
  }
}

html {
  box-sizing: border-box;

  font-size: 16px;
  font-family: $font-family-serif;
}

// for debugging
pre {
  line-height: 12px !important;
}

code {
  font-family: $font-family-mono;
  font-size: 11px;
}


header {
  $header-top-color:    hsl(0, 0%, 30%);
  $header-bottom-color: hsl(0, 0%, 18%);

  background: linear-gradient($header-top-color, $header-bottom-color);

  nav {
    display: flex;
    flex-direction: row;

    margin: 0 auto;

    padding: .5rem;
    gap: 1rem;

    a {
      transition: all 200ms;

      flex: 1;
      display: flex;

      min-height: 2.4rem;

      align-items: center;
      justify-content: center;

      text-decoration: none;

      color: white;

      font-family: $font-family-ui;
      font-size: 13px;
      font-weight: bold;

      text-decoration-thickness: 2px;
      text-underline-offset:     3px;
      border-radius: 6px;

      &:hover {
        background: #1c1c1c;
      }

      &.active {
        text-decoration:           underline;

        background: #1c1c1c;
      }
    }

    @include mobile-only {
      a.desktop-only {
        display: none;
      }
    }
  }
}

main {
  padding: .5rem .5rem;
}

main.dictionary {
  .search-box {
    margin-top:    1rem;
    margin-bottom: 1rem;

    input {
      padding: .3rem .8rem;
      min-width: 20rem;

      font-family: $font-family-ui;
      font-size: 12px;

      border-radius: 12px;
      border: 1px solid lightgray;
    }
  }
}

main#verb-test {
  ul {
    display:               grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 1rem;

    li {
      line-height: 2.8rem;

      border-bottom: 1px dashed gray;
      font-size: 1.1rem;

      display: grid;
      grid-template-columns: 1fr 1fr;

      label {
        padding-right: 1rem;
      }
    }
  }
}

main#verb-quiz {
  .quiz-progress {
    margin: 0 auto;
    width: 80%;
    display: flex;

    padding-bottom: 1rem;

    justify-content: center;
    gap: 4px;

    align-items: center;
    justify-items: center;

    li {
      //flex: 1;

      flex-shrink: 0;
      flex-grow: 0;

      $w: 10px;

      height: $w;
      width: $w;

      border: 1px solid gray;
      border-radius: 50%;

      &.complete {
        background-color: gray;
      }

      &.current {
        border-width: 2px;

        min-width: 12px;
        min-height: 12px;
      }
    }
  }

  h5 {
    font-family: $font-family-serif;

    font-size:   2.6rem;
    font-weight: normal;

    text-align: center;
    padding-bottom: .4rem;
  }

  .quiz ol {
    margin: 0 auto;
    max-width: 800px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: .7rem;

    @include mobile-only {
      grid-template-columns: 1fr;
      grid-gap: .6rem;
    }

    * {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      user-select: none;
    }

    li {
      display: flex;

      $button-color:           $pantone-classic-blue;
      $button-color-dark:      color.scale($button-color, $lightness: -20%);
      $button-color-light:     color.scale($button-color, $lightness: +20%);
      $button-color-correct:   hsl(114, 50%, 28%);
      $button-color-incorrect: hsl(0, 73%, 35%);

      @keyframes to-green {
        0% {
          background-color: $button-color;
          border-color: color.scale($button-color, $lightness: -20%)
        }

        50% {
          background-color: $button-color-correct;
          border-color: color.scale($button-color-correct, $lightness: -20%);
        }

        100% {
          background-color: $button-color;
          border-color: color.scale($button-color, $lightness: -20%)
        }
      }

      @keyframes to-red {
        0% {
          background-color: $button-color;
          border-color: color.scale($button-color, $lightness: -20%)
        }

        50% {
          background-color: $button-color-incorrect;
          border-color: color.scale($button-color-incorrect, $lightness: -20%)
        }

        100% {
          background-color: $button-color;
          border-color: color.scale($button-color, $lightness: -20%)
        }
      }

      button {
        flex: 1;

        transition: all 0.5s ease;

        min-height: 3rem;

        border-radius: 10px;
        padding-left: 14px;

        font-family: $font-family-ui;
        font-size:   13px;
        font-weight: bold;

        @include mobile-only {
          min-height: 3.2rem;
          font-size: 14px;
        }

        background-color: $button-color;
        border: 4px solid $button-color-dark;

        &:hover {
          background: $button-color-dark;
        }

        &:active, .active {
          background:   $button-color-light;
          border-color: $button-color-light;
        }

        &.pressed {
          pointer-events: none;
        }

        &.pressed.correct {
        }

        &.pressed.correct {
          animation: to-green 200ms 1;
        }

        &.pressed.incorrect {
          animation: to-red 200ms 1;
        }

        color: white;

        /* offset-x | offset-y | blur-radius | color */
        //text-shadow: 1px 1px 2px black;
        text-shadow: 0px 1px 1px hsla(0, 0%, 10%, 0.8);

        /* offset-x | offset-y | blur-radius | spread-radius | color */
        //box-shadow: 0px 2px 2px 0px hsla(0, 0%, 50%, 0.9);

        /** these options apply to the flex container content (text node) */
        display: flex;
        justify-content: left;
        align-items: center;
        cursor: pointer;

        user-select: none;
      }
    }

    li.pass {
      //border: 1px solid black;
      grid-column: 1/3;
    }
  }

  .results {
    table {
      border-collapse: collapse;

      .verb {
        font-family: $font-family-serif;
        font-size: 20px;
        font-weight: normal;

        min-width: 8rem;
      }

      td {
        font-family: $font-family-ui;
        font-size: 13px;

        vertical-align: baseline;

        padding-right: 2rem;
      }

      .verb {
        width: 14rem;
      }

      tr.success {
        .verb {
          color: green;
        }
      }

      tr.failure {
        .verb {
          color: darkred;
        }

        .given {
          color: darkred;
          text-decoration: line-through;
        }
      }
    }

    .wrong-pick {
      li {
        display: flex;
        align-items: baseline;

        * {
          flex: 1;
        }

        font-family: $font-family-ui;
        font-size: 14px;

        .inf {
          font-family: $font-family-serif;
          font-size: 20px;
          font-weight: normal;
        }

        .chosen {
          color: hsl(0, 100%, 27%);
          text-decoration: line-through;
          text-decoration-thickness: 1px;
          text-decoration-color: color.scale(hsl(0, 100%, 27%), $alpha: -20%);
        }

        .correct {
          color: darkgreen;
        }
      }
    }
  }
}

ul#verb-test-cards {
  border: 2px solid blue;
}

ul#cards {
  li.card {
    break-inside: avoid;

    padding-bottom: .5rem;
    margin-bottom: .5rem;
    border-bottom: 1px dashed #bad3ba;

    h3 {
      padding-bottom: .2rem;

      font-family: $font-family-serif;
      font-weight: normal !important;
      font-size: 1.3rem;

      span {
        font-weight: normal;
        padding-left: .2rem;
        font-size: .8rem;
        color: #252525;

        font-variant-numeric: tabular-nums;
      }
    }

    table {
      width: 100%;

      td {
        vertical-align: top;
      }
    }

    .present_p1_s,
    .present_p2_s,
    .perfect_p1_s,
    .ppp_n {
      width: 20%;
    }

    th {
      font-size: .7rem;
      color: #464646;

      font-family: $font-family-ui;
      font-weight: 500;
    }

    .german {
      text-align: right;
    }
  }
}

.lecture-selector {
  h1 {
    font-family: $font-family-ui;
    font-size: 28px;
    font-weight: bold;

    margin-bottom: 1rem;
  }

  h2 {
    font-family: $font-family-ui;
    font-size: 18px;
    font-weight: bold;

    margin-bottom: 1rem;
  }

  ol {
    //background-color: blue;
    font-family: $font-family-ui;
    font-size: 13px;

    margin-bottom: 1rem;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));

    li {
      min-height: 2rem;

      display: flex;

      label {
        flex: 1;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        user-select: none;

        font-family: system-ui, sans-serif;
        font-weight: bold;
        line-height: 1.1;
        display: grid;
        grid-template-columns: 18px auto;
        gap: 10px;

        align-items: center;

        input[type="checkbox"] {
          -webkit-appearance: none;
          appearance: none;
          background-color: hsl(0, 0%, 99%);
          margin: 0;

          width: 20px;
          height: 20px;

          border: 2px solid hsl(0, 0%, 40%);
          border-radius: 2px;

          display: grid;
          place-content: center;

          transform: translateY(1px);
          transition: all 200ms ease-in-out;

          &:checked {
            $color:  hsl(219, 92%, 58%);
            background-color: $color;
            border-color: $color;
          }
        }

        input[type="checkbox"]::before {
          content:    "";
          width:      12px;
          height:     12px;
          transform:  scale(0);
          transition: 120ms transform ease-in-out;
          background-color: white;
        }

        input[type="checkbox"]:checked::before {
          transform: scale(1);

          transform-origin: bottom left;
          clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        }
      }
    }
  }

  .button-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    @mixin button($primary-color) {
      color: black;
      cursor: pointer;

      border: 1px solid rgba(27, 31, 35, 0.15);
      box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
      border-radius: 6px;

      background-color: $primary-color;

      transition: all 100ms ease-in-out;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      user-select: none;

      &:active {
        background-color: color.scale($primary-color, $lightness: -20%);
        box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset
      }

      &:disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.8;
      }
    }

    li {
      flex: 1;
      display: flex;

      button {
        flex: 1;

        font-family: $font-family-ui;
        font-size: 13px;
        font-weight: bold;

        min-height: 42px;

        @include button(hsl(0, 0%, 100%));
      }
    }

    .start {
      grid-column: 1/3;

      button {
        @include button(hsl(210, 38%, 50%));
        color: white;
      }
    }
  }
}

@media print {
  html {
    font-size: 10pt;
  }

  body {
    margin: 0;
  }

  @page {
    margin: 8mm;
  }
}

