@font-face {
  font-family: "Quadraat";
  src: url("https://latin-cards.fsvehla.at/fonts/Quadraat-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Quadraat";
  font-style: italic;
  src: url("https://latin-cards.fsvehla.at/fonts/Quadraat-Italic.otf") format("opentype");
}
@font-face {
  font-family: "Quadraat";
  font-weight: bold;
  src: url("https://latin-cards.fsvehla.at/fonts/Quadraat-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Quadraat";
  font-weight: bold;
  font-style: italic;
  src: url("https://latin-cards.fsvehla.at/fonts/Quadraat-BoldItalic.otf") format("opentype");
}
/** reset **/
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
ol, ul {
  list-style: none;
}
td, th, ul, li {
  padding: 0;
  margin: 0;
}
/** end: reset */
html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: Quadraat;
}
pre {
  line-height: 12px !important;
}
code {
  font-family: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono", "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro", "Fira Mono", "Droid Sans Mono", "Courier New", monospace;
  font-size: 11px;
}
header {
  background: linear-gradient(#4d4d4d, #2e2e2e);
}
header nav {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 0.5rem;
  gap: 1rem;
}
header nav a {
  transition: all 200ms;
  flex: 1;
  display: flex;
  min-height: 2.4rem;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
  border-radius: 6px;
}
header nav a:hover {
  background: #1c1c1c;
}
header nav a.active {
  text-decoration: underline;
  background: #1c1c1c;
}
@media screen and (max-width: 599px) {
  header nav a.desktop-only {
    display: none;
  }
}
main {
  padding: 0.5rem 0.5rem;
}
main.dictionary .search-box {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
main.dictionary .search-box input {
  padding: 0.3rem 0.8rem;
  min-width: 20rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  border-radius: 12px;
  border: 1px solid lightgray;
}
main#verb-test ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 1rem;
}
main#verb-test ul li {
  line-height: 2.8rem;
  border-bottom: 1px dashed gray;
  font-size: 1.1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
main#verb-test ul li label {
  padding-right: 1rem;
}
main#verb-quiz .quiz-progress {
  margin: 0 auto;
  width: 80%;
  display: flex;
  padding-bottom: 1rem;
  justify-content: center;
  gap: 4px;
  align-items: center;
  justify-items: center;
}
main#verb-quiz .quiz-progress li {
  flex-shrink: 0;
  flex-grow: 0;
  height: 10px;
  width: 10px;
  border: 1px solid gray;
  border-radius: 50%;
}
main#verb-quiz .quiz-progress li.complete {
  background-color: gray;
}
main#verb-quiz .quiz-progress li.current {
  border-width: 2px;
  min-width: 12px;
  min-height: 12px;
}
main#verb-quiz h5 {
  font-family: Quadraat;
  font-size: 2.6rem;
  font-weight: normal;
  text-align: center;
  padding-bottom: 0.4rem;
}
main#verb-quiz .quiz ol {
  margin: 0 auto;
  max-width: 800px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.7rem;
}
@media screen and (max-width: 599px) {
  main#verb-quiz .quiz ol {
    grid-template-columns: 1fr;
    grid-gap: 0.6rem;
  }
}
main#verb-quiz .quiz ol * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}
main#verb-quiz .quiz ol li {
  display: flex;
}
@keyframes to-green {
  0% {
    background-color: #224877;
    border-color: #1b3a5f;
  }
  50% {
    background-color: #2b6b24;
    border-color: #22561d;
  }
  100% {
    background-color: #224877;
    border-color: #1b3a5f;
  }
}
@keyframes to-red {
  0% {
    background-color: #224877;
    border-color: #1b3a5f;
  }
  50% {
    background-color: #9a1818;
    border-color: #7c1313;
  }
  100% {
    background-color: #224877;
    border-color: #1b3a5f;
  }
}
main#verb-quiz .quiz ol li button {
  flex: 1;
  transition: all 0.5s ease;
  min-height: 3rem;
  border-radius: 10px;
  padding-left: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-weight: bold;
  background-color: #224877;
  border: 4px solid #1b3a5f;
  color: white;
  /* offset-x | offset-y | blur-radius | color */
  text-shadow: 0px 1px 1px rgba(26, 26, 26, 0.8);
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  /** these options apply to the flex container content (text node) */
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
@media screen and (max-width: 599px) {
  main#verb-quiz .quiz ol li button {
    min-height: 3.2rem;
    font-size: 14px;
  }
}
main#verb-quiz .quiz ol li button:hover {
  background: #1b3a5f;
}
main#verb-quiz .quiz ol li button:active, main#verb-quiz .quiz ol li button .active {
  background: #316aaf;
  border-color: #316aaf;
}
main#verb-quiz .quiz ol li button.pressed {
  pointer-events: none;
}
main#verb-quiz .quiz ol li button.pressed.correct {
  animation: to-green 200ms 1;
}
main#verb-quiz .quiz ol li button.pressed.incorrect {
  animation: to-red 200ms 1;
}
main#verb-quiz .quiz ol li.pass {
  grid-column: 1/3;
}
main#verb-quiz .results table {
  border-collapse: collapse;
}
main#verb-quiz .results table .verb {
  font-family: Quadraat;
  font-size: 20px;
  font-weight: normal;
  min-width: 8rem;
}
main#verb-quiz .results table td {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 13px;
  vertical-align: baseline;
  padding-right: 2rem;
}
main#verb-quiz .results table .verb {
  width: 14rem;
}
main#verb-quiz .results table tr.success .verb {
  color: green;
}
main#verb-quiz .results table tr.failure .verb {
  color: darkred;
}
main#verb-quiz .results table tr.failure .given {
  color: darkred;
  text-decoration: line-through;
}
main#verb-quiz .results .wrong-pick li {
  display: flex;
  align-items: baseline;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
}
main#verb-quiz .results .wrong-pick li * {
  flex: 1;
}
main#verb-quiz .results .wrong-pick li .inf {
  font-family: Quadraat;
  font-size: 20px;
  font-weight: normal;
}
main#verb-quiz .results .wrong-pick li .chosen {
  color: #8a0000;
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  text-decoration-color: rgba(138, 0, 0, 0.8);
}
main#verb-quiz .results .wrong-pick li .correct {
  color: darkgreen;
}
ul#verb-test-cards {
  border: 2px solid blue;
}
ul#cards li.card {
  break-inside: avoid;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px dashed #bad3ba;
}
ul#cards li.card h3 {
  padding-bottom: 0.2rem;
  font-family: Quadraat;
  font-weight: normal !important;
  font-size: 1.3rem;
}
ul#cards li.card h3 span {
  font-weight: normal;
  padding-left: 0.2rem;
  font-size: 0.8rem;
  color: #252525;
  font-variant-numeric: tabular-nums;
}
ul#cards li.card table {
  width: 100%;
}
ul#cards li.card table td {
  vertical-align: top;
}
ul#cards li.card .present_p1_s,
ul#cards li.card .present_p2_s,
ul#cards li.card .perfect_p1_s,
ul#cards li.card .ppp_n {
  width: 20%;
}
ul#cards li.card th {
  font-size: 0.7rem;
  color: #464646;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
}
ul#cards li.card .german {
  text-align: right;
}
.lecture-selector h1 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 1rem;
}
.lecture-selector h2 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 1rem;
}
.lecture-selector ol {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 13px;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
}
.lecture-selector ol li {
  min-height: 2rem;
  display: flex;
}
.lecture-selector ol li label {
  flex: 1;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  font-family: system-ui, sans-serif;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 18px auto;
  gap: 10px;
  align-items: center;
}
.lecture-selector ol li label input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fcfcfc;
  margin: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #666666;
  border-radius: 2px;
  display: grid;
  place-content: center;
  transform: translateY(1px);
  transition: all 200ms ease-in-out;
}
.lecture-selector ol li label input[type=checkbox]:checked {
  background-color: #3176f6;
  border-color: #3176f6;
}
.lecture-selector ol li label input[type=checkbox]::before {
  content: "";
  width: 12px;
  height: 12px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  background-color: white;
}
.lecture-selector ol li label input[type=checkbox]:checked::before {
  transform: scale(1);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.lecture-selector .button-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
}
.lecture-selector .button-group li {
  flex: 1;
  display: flex;
}
.lecture-selector .button-group li button {
  flex: 1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-weight: bold;
  min-height: 42px;
  color: black;
  cursor: pointer;
  border: 1px solid rgba(27, 31, 35, 0.15);
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  border-radius: 6px;
  background-color: white;
  transition: all 100ms ease-in-out;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}
.lecture-selector .button-group li button:active {
  background-color: #cccccc;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
}
.lecture-selector .button-group li button:disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.8;
}
.lecture-selector .button-group .start {
  grid-column: 1/3;
}
.lecture-selector .button-group .start button {
  color: black;
  cursor: pointer;
  border: 1px solid rgba(27, 31, 35, 0.15);
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  border-radius: 6px;
  background-color: #4f80b0;
  transition: all 100ms ease-in-out;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  color: white;
}
.lecture-selector .button-group .start button:active {
  background-color: #3f668d;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
}
.lecture-selector .button-group .start button:disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.8;
}
@media print {
  html {
    font-size: 10pt;
  }

  body {
    margin: 0;
  }

  @page {
    margin: 8mm;
  }
}
/*# sourceMappingURL=index.e146b242.css.map */
